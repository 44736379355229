<template>
  <div class="my_bind_licence">
    <div class="warpper">
      <h2 class="title">授权绑定</h2>
      <div class="platforms">
        <div class="platform">
          <div class="platform_part flex-align-between">
            <p class="name">银川智慧教育平台</p>
            <button class="binding csp" @click.prevent="open()">{{isBind?'解除绑定':'添加绑定'}}</button>
          </div>
          <span class="status">{{ isBind?'已绑定':'未绑定'}}</span>
        </div>
      </div>
    </div>
    <el-dialog class="dialog" :class="{'finish':finish}" :visible.sync="dialogVisible" width="489px" top="30vh" center>
      <div v-if="!finish">
        <p class="confirm">确认解绑</p>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="relieveBind">确 定</el-button>
        </div>
      </div>
      <div v-else :class="{'finish-part':finish}">
        <div class="tick">
          <i class="el-icon-check"></i>
        </div>
        <p>{{bindText}}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    data() {
      return {
        dialogVisible: false,
        finish: false,
        isBind: false,
        bindText: '绑定成功',
      };
    },
    methods: {
      async open() {
        if (this.isBind) { //解除绑定
          this.finish = false;
          this.dialogVisible = true;
        } else {
          // 跳转至第三方平台 绑定
          let params = {
            type: 'ycThird',
            path: `${window.location.protocol}//${window.location.host}/my/bindLicence`
          }
          let resData = await this.$Api.Home.loginThird(params)
          // console.log(resData);
          if (resData.code == 200 && resData.data) {
            window.location.href = resData.data.redirectUrl
          }
        }
      },
      // 绑定
      bindUser() {
        let token = this.$store.state.user.token;
        let params = {
          code: this.$route.query.code,
          state: this.$route.query.state,
          session_state: this.$route.query.session_state,
          path: `${window.location.protocol}//${window.location.host}/my/bindLicence`
        }

        axios({
            url: `/auth/user/info/bindUser?type=ycThird`,
            method: "post",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              // "Authorization": "Bearer " + sessionStorage.token
              "Authorization": "Bearer " + token
            },
            data: params
          })
          .then((resData) => {
            // console.log(resData.data.code);
            if (resData.data.code == 200) {
              this.finish = true;
              this.dialogVisible = true;
              this.getBind();
            } else {
              this.$notify.error({
                title: "绑定失败",
              });
            }
          }).catch((resData) => {
            console.log(resData);
          })
      },
      // 解绑
      relieveBind() {
        let token = this.$store.state.user.token
        axios({
            url: `/auth/user/info/relieveBind?type=ycThird`,
            method: "delete",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              // "Authorization": "Bearer " + sessionStorage.token
              "Authorization": "Bearer " + token
            }
          })
          .then((resData) => {
            // console.log(resData.data);
            if (resData.data.code == 200) {
              window.location.href = `${resData.data.data.url}https://${window.location.host}/my/bindLicence`
              // this.dialogVisible = true;
              this.bindText = '解绑成功'
              this.finish = true;

              this.getBind();
            } else {
              this.$notify.error({
                title: "解绑失败",
              });
            }
          }).catch(() => {
            console.log(resData);
          })
      },
      async getBind() {
        let resData = await this.$Api.Home.getBindList()
        // console.log(resData)
        if (resData.data) {
          this.isBind = resData.data.ycThird;
        }
        // console.log(this.isBind)
      },
    },
    mounted() {
      this.getBind();
    },
    activated() {
      this.getBind();
      if (this.$route.query.code && this.$route.query.state) {
        this.bindUser();
      }
    }
  };
</script>

<style lang="less">
  .my_bind_licence {
    border-radius: 10px;
    // box-shadow: 0px 5px 23px 0px rgba(22, 115, 255, 0.1);
    max-width: 819px;
    width: 819px;

    background: #ffffff;

    .warpper {
      padding-left: 9px;
    }

    .title {
      border-bottom: 1px solid #f9f9f9;
      padding-left: 35px;
      line-height: 69px;

      font-size: 24px;
    }

    .platforms {
      padding: 35px 50px;
    }

    .platform_part {
      p.name {
        font-size: 23px;
        color: #333;
      }

      .binding {
        padding: 0 45px;
        line-height: 42px;

        font-size: 18px;
        color: #fff;

        background: linear-gradient(90deg, #2272EC, #3A7BF6);
        box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
        border-radius: 5px;
      }
    }

    .platform .status {
      display: inline-block;
      margin-top: 12px;

      font-size: 16px;
      color: #666;
    }

    .confirm {
      margin-top: 30px;
      font-size: 24px;
      color: #333;
      text-align: center;
    }

    /deep/ .finish {
      .el-dialog {
        background-image: url("../../assets/images/my/binding-bg.png");
        background-repeat: no-repeat;
        background-position-x: -3px;
        background-size: 102% auto;
      }

      .el-dialog__close {
        color: #fff;
      }

      .finish-part {
        div.tick {
          width: 75px;
          height: 75px;
          margin: -28px auto 94px;
          background: rgba(49, 97, 210, 0.5);
          border: 4px solid #3B99E9;
          // border-image: linear-gradient(180deg, #3B99E9, #4474F2) 10 10;
          border-radius: 50%;
          position: relative;
        }

        p {
          margin-bottom: 25px;
          color: #2975ef;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
        }

        i {
          font-size: 44px;
          color: #fff;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }


    /deep/ .el-dialog__close {
      font-size: 20px;
      font-weight: 600;
      color: #777;
    }



    /deep/ .dialog-footer {
      margin: 65px auto 24px;
      text-align: center;

      .el-button {
        width: 161px;
        height: 42px;
        border: 1px solid #F9F9F9;
        background: linear-gradient(90deg, #2272EC, #3A7BF6);
        box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
        border-radius: 5px;
        border: 1px solid transparent;
        color: #fff;
      }

      .el-button--default {
        margin-right: 50px;
        color: #2774EE;
        border: 1px solid #2774EE;
        background: #fff;
      }
    }
  }

  @media screen and (max-width: 1250px) {
    .my_bind_licence {
      max-width: 660px;
      width: 660px;
    }
  }
</style>